import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { AutoreconciliationRepository } from "../domain/AutoreconciliationRepository";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Page } from "~/context/Shared/domain/page/Page";
import { Autoreconciliation } from "../domain/Autoreconciliation";
import { AutoreconciliationCredit } from "../domain/AutoreconciliationCredit";
import { VectiResult } from "~/context/Shared/domain/VectiResult";
import { AutoreconciliationProcess } from "../domain/AutoreconciliationProcess";



export function createApiAutoreconciliationRepository(): AutoreconciliationRepository {
  return new ApiAutoreconciliationRepository();
}

export class ApiAutoreconciliationRepository extends APIRepository implements AutoreconciliationRepository {

  constructor() {
    super(vectiAppApiUrl());
  }


  async findMovementAutoreconciliationsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Autoreconciliation>> {
    console.log("findMovementAutoreconciliationsByCompanyAndCriteria: companyUid: " + companyUid + " criteria: " + JSON.stringify(criteria));
    try {
      const movements = await this.getMovementsWithAutoreconciliationsByCompanyUid(companyUid);
      console.log("movements: " + JSON.stringify(movements));
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedAutoreconciliations = this.filterByCriteria(criteria, movements);
      if (paginatedAutoreconciliations.length === 0) {
        return Promise.resolve(
          new Page<Autoreconciliation>(
            paginatedAutoreconciliations.length,
            paginatedAutoreconciliations.slice(start, end)
          )
        );
      } else {
        console.log("paginatedAutoreconciliations ERROR: " + JSON.stringify(paginatedAutoreconciliations));
        return Promise.resolve(new Page<Autoreconciliation>(0, []));
      }
    } catch (error) {
      console.error("Error from SQL (findMovementAutoreconciliationsByCompanyAndCriteria): " + error);
      return Promise.resolve(new Page<Autoreconciliation>(0, []));
    }

  }

  async findAutoreconciliationsCreditByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<AutoreconciliationCredit>> {
    // console.log("findAutoreconciliationsCreditByCompanyAndCriteria: companyUid: " + companyUid + " criteria: " + JSON.stringify(criteria));
    try {
      const autoreconciliationsCredit = await this.getAutoreconciliationsCreditByCompanyUid(companyUid);
      // console.log("autoreconciliationsCredit: " + JSON.stringify(autoreconciliationsCredit));
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedAutoreconciliations = this.filterCreditByCriteria(criteria, autoreconciliationsCredit);
      if (paginatedAutoreconciliations.length > 0) {
        return Promise.resolve(
          new Page<AutoreconciliationCredit>(
            paginatedAutoreconciliations.length,
            paginatedAutoreconciliations.slice(start, end)
          )
        );
      } else {
        console.log("paginatedAutoreconciliations ERROR: " + JSON.stringify(paginatedAutoreconciliations));
        return Promise.resolve(new Page<AutoreconciliationCredit>(0, []));
      }
    } catch (error) {
      console.error("Error from SQL (findMovementAutoreconciliationsByCompanyAndCriteria): " + error);
      return Promise.resolve(new Page<AutoreconciliationCredit>(0, []));
    }

  }

  async addAutoreconciliationGroupByCompany(companyUid: string, saveOptions: AutoreconciliationProcess[]): Promise<VectiResult> {
    console.log("addAutoreconciliationGroupByCompany: companyUid: " + companyUid);
    try {
      const autoreconciliationGroup = await this.addAutoreconciliationGroup(companyUid, saveOptions);
      console.log("autoreconciliationGroup: " + JSON.stringify(autoreconciliationGroup));
      return Promise.resolve(new VectiResult(true, "Autoreconciliation group added"));
    } catch (error) {
      console.log("Error from SQL (addAutoreconciliationGroupByCompany): " + error);
      return Promise.reject(new VectiResult(false, "Error from SQL (addAutoreconciliationGroupByCompany): " + error));
    }
  }

  async addAutoreconciliationByCompany(companyUid: string, saveOption: AutoreconciliationProcess): Promise<VectiResult> {
    console.log("addAutoreconciliationByCompany: companyUid: " + companyUid);
    try {
      const autoreconciliation = await this.addAutoreconciliation(companyUid, saveOption);
      console.log("autoreconciliation: " + JSON.stringify(autoreconciliation));
      return Promise.resolve(new VectiResult(true, "Autoreconciliation added"));
    } catch (error) {
      console.log("Error from SQL (addAutoreconciliationByCompany): " + error);
      return Promise.reject(new VectiResult(false, "Error from API: " + error));

    }
  }



  getAutoreconciliationsCreditByCompanyUid(companyUid: string): Promise<AutoreconciliationCredit[]> {
    try {
      return this.doGet<AutoreconciliationCredit[]>("/api/autoreconciliations/credit/company/" + companyUid.toString());
    } catch (error) {
      console.error("Error from API (getAutoreconciliationsCreditByCompanyUid): " + error);
      return Promise.resolve(Array<AutoreconciliationCredit>());
    }
  }


  addAutoreconciliationGroup(companyUid: string, saveOptions: AutoreconciliationProcess[]): Promise<void> {

    try {
      const resp = this.doPostNew("/api/autoreconciliations/company", null, {
        saveOptions: saveOptions,
        company_uid: companyUid,
      });
      console.log("addAutoreconciliation Response: " + JSON.stringify(resp));
      return Promise.resolve();

    } catch (error) {
      console.error("Error from API (addAutoreconciliationGroup): " + error);
      return Promise.reject();
    }

  }

  async addAutoreconciliation(companyUid: string, saveOption: AutoreconciliationProcess): Promise<void> {

    try {
      const resp = await this.doPostNew("/api/autoreconciliations/id", null, {
        saveOption: saveOption,
        company_uid: companyUid,
      });
      console.log("addAutoreconciliation Response: " + JSON.stringify(resp));
      return Promise.resolve();

    } catch (error) {
      console.error("Error from API (addAutoreconciliationGroup): " + error);
      return Promise.reject();
    }
  }



  getMovementsWithAutoreconciliationsByCompanyUid(companyUid: string): Promise<Autoreconciliation[]> {
    try {
      return this.doGet<Autoreconciliation[]>("/api/autoreconciliations/movement/company/" + companyUid.toString());
    } catch (error) {
      console.error("Error from API (getMovementsWithAutoreconciliationsByCompanyUid): " + error);
      return Promise.resolve(Array<Autoreconciliation>());
    }
  }

  filterCreditByCriteria(
    criteria: Criteria,
    _autoreconciliations: AutoreconciliationCredit[]
  ): AutoreconciliationCredit[] {
    let autorecon = Array<AutoreconciliationCredit>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "autoreconciliation_state": {
            if (_autoreconciliations.length > 0) {
              console.log("filter.value: " + filter.value);
              console.log("autoreconciliations: " + JSON.stringify(_autoreconciliations));
              autorecon = _autoreconciliations.filter((autoreconciliationCredit) =>
                autoreconciliationCredit.autoreconciliation.autoreconciliation_state
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
              console.log("autorecon: " + JSON.stringify(autorecon));
            }
            break;
          }
        }
      }
      console.log("autorecon final: " + JSON.stringify(autorecon));
      return autorecon;
    } else {
      return _autoreconciliations;
    }
  }


  filterByCriteria(
    _criteria: Criteria,
    _autoreconciliations: Autoreconciliation[]
  ): Autoreconciliation[] {
    return _autoreconciliations;
    /*
    let movements = Array<Autoreconciliation>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "movement_account_number": {
            if (_autoreconciliations.length > 0) {
              movements = _autoreconciliations.filter((movement) =>
                movement.movement_account_number
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_description": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_description
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_type": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "account_uid": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.account_uid
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_balance": {
            if (filter.operator === ">") {
              console.log("filtrando por " + filter.operator);
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance > parseFloat(filter.value)
              );
            } else if (filter.operator === "<") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance < parseFloat(filter.value)
              );
            } else if (filter.operator === ">=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance >= parseFloat(filter.value)
              );
            } else if (filter.operator === "<=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance <= parseFloat(filter.value)
              );
            } else if (filter.operator === "=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance === parseFloat(filter.value)
              );
            } else if (filter.operator === "!=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance != parseFloat(filter.value)
              );
            }
 
            break;
          }
 
          case "company_uid": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.company_uid
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
 
        }
      }
    }
    return movements;
    */
  }


}
