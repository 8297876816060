import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Page } from "~/context/Shared/domain/page/Page";
import { InvoiceRepository } from "../domain/InvoiceRepository";
import { Invoice } from "../domain/Invoice";



export function createApiInvoiceRepository(): InvoiceRepository {
  return new ApiInvoiceRepository();
}


export class ApiInvoiceRepository extends APIRepository implements InvoiceRepository {
  constructor() {
    super(vectiAppApiUrl());
  }
  updateInvoice(_invoice: Invoice): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getInvoicesByLegalId(_legalId: string): Promise<Invoice[]> {
    throw new Error("Method not implemented.");
  }
  getInvoiceByInvoiceUid(_invoiceUid: string): Promise<Invoice | undefined> {
    throw new Error("Method not implemented.");
  }
  getInvoiceByCompanyUidPaginate(_companyId: string, _page: number): Promise<Invoice[]> {
    throw new Error("Method not implemented.");
  }
  getInvoicesByCriteria(_criteria: Criteria): Promise<Page<Invoice>> {
    throw new Error("Method not implemented.");
  }

  async getInvoicesByCompanyUid(companyUid: string): Promise<Invoice[]> {
    const a = await this.doGet<Invoice[]>("/api/invoices/company/" + companyUid.toString());
    return a;
  }


  async getInvoicesByCompanyUidDistance(companyId: string, movementId: string): Promise<Invoice[]> {
    const a = await this.doGet<Invoice[]>("/api/invoices/company/" + companyId.toString() + "/movement/" + movementId.toString());
    return a;
  }


  async getInvoicesByCompanyDistanceAndCriteria(companyId: string, movementId: string, criteria: Criteria): Promise<Page<Invoice>> {
    const invoices = await this.getInvoicesByCompanyUidDistance(companyId, movementId);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedInvoices = this.filterByCriteria(criteria, invoices);
    return Promise.resolve(
      new Page<Invoice>(
        paginatedInvoices.length,
        paginatedInvoices.slice(start, end)
      )
    );

  }

  async getInvoicesByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Invoice>> {

    const invoices = await this.getInvoicesByCompanyUid(companyUid);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedInvoices = this.filterByCriteria(criteria, invoices);
    return Promise.resolve(
      new Page<Invoice>(
        paginatedInvoices.length,
        paginatedInvoices.slice(start, end)
      )
    );

  }





  filterByCriteria(
    criteria: Criteria,
    _invoices: Invoice[]
  ): Invoice[] {
    //TODO: implementar filtros
    let invoices = Array<Invoice>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "company_uid": {
            if (_invoices.length > 0) {
              invoices = _invoices.filter((invoice) =>
                invoice.company_uid.toString()
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }

          case "invoice_number": {
            if (_invoices.length > 0) {
              invoices = _invoices.filter((invoice) =>
                invoice.invoice_number
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "invoice_type": {
            if (_invoices.length > 0) {
              invoices = _invoices.filter((invoice) =>
                invoice.invoice_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "invoice_sender_legal_id": {
            if (_invoices.length > 0) {
              invoices = _invoices.filter((invoice) =>
                invoice.invoice_sender_legal_id
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "invoice_state": {
            if (_invoices.length > 0) {
              invoices = _invoices.filter((invoice) =>
                invoice.invoice_state
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
        }
      }
    }
    return invoices;
  }


}
