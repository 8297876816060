import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { SynchronizationRepository } from "../domain/SynchronizationRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Synchronization } from "../domain/Synchronization";
import { CompanyId } from "~/context/Company/domain/CompanyId";

export function createApiSynchronizationRepository(): SynchronizationRepository {
  return new ApiSynchronizationRepository();
}

export class ApiSynchronizationRepository extends APIRepository implements SynchronizationRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  synchronize(companyId: CompanyId): Promise<Synchronization> {
    return this.doGet<Synchronization>("/api/synchronization/company/" + companyId.toString(), null);
  }

  synchronizeBankLastMonth(companyId: CompanyId): Promise<Synchronization> {
    const month = ((new Date().getMonth()) + 1).toString().padStart(2, '0');
    const year = new Date().getFullYear().toString();
    return this.doGet<Synchronization>("/api/synchronization/account/company/" + companyId.toString() + "/year/" + year + "/month/" + month, null);
  }

  synchronizeSIILastMonth(companyId: CompanyId): Promise<Synchronization> {
    const month = ((new Date().getMonth()) + 1).toString().padStart(2, '0');
    const year = new Date().getFullYear().toString();
    return this.doGet<Synchronization>("/api/synchronization/sii/company/" + companyId.toString() + "/year/" + year + "/month/" + month, null);
  }

  getSynchronizeAutoconciliationLastMonth(companyId: CompanyId): Promise<Synchronization> {
    console.log("llamando getSynchronizeAutoconciliationLastMonth");
    const month = ((new Date().getMonth()) + 1).toString().padStart(2, '0');
    const year = new Date().getFullYear().toString();
    return this.doGet<Synchronization>("/api/synchronization/autoreconciliation/company/" + companyId.toString() + "/year/" + year + "/month/" + month, null);
  }




}
