import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { DocumentRepository } from "../domain/DocumentRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Page } from "~/context/Shared/domain/page/Page";
import { PageFull } from "~/context/Shared/domain/page/PageFull";
import { Document } from "../domain/Document";

export function createApiDocumentRepository(): DocumentRepository {
  return new ApiDocumentRepository();
}


export class ApiDocumentRepository extends APIRepository implements DocumentRepository {
  constructor() {
    super(vectiAppApiUrl());
  }


  async getDocumentsByLegalId(_legalId: string): Promise<Document[]> {
    throw new Error("Method not implemented.");
    //const a = await this.doGet<Document[]>("/api/documents/company/legalId" + legalId.toString());
    //return a;
  }

  async getDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    //console.log("call getDocumentsByCompanyUid: " + companyUid.toString());
    const a = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString());
    // console.log("return getDocumentsByCompanyUid: " + JSON.stringify(a));
    return a;
  }

  async getIncomeDocumentsByCompanyAndClient(companyUid: string, clientUid: string): Promise<Document[]> {
    const docs = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString() + "/client/" + clientUid.toString());
    return docs;
  }



  async getDocumentsByCompanyUidAndCriteriaInfra(companyUid: string, criteria: Criteria): Promise<void> {
    //console.log("call getDocumentsByCompanyUid: " + companyUid.toString());

    const a = await this.doPostNew("/api/documents/company/criteria", null, {
      companyUid: companyUid,
      criteria: criteria
    });

    return a;
  }

  async getIncomeDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/income/company/" + companyUid.toString());
    return a;
  }

  async getOutcomeDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/outcome/company/" + companyUid.toString());
    console.log("return getOutcomeDocumentsByCompanyUid: " + JSON.stringify(a));
    return a;
  }


  async getDocumentByDocumentUidAndType(documentUid: string, type: string): Promise<Document> {
    //console.log("call getDocumentsBgetDocumentByDocumentUidAndTypeyDocumentUid: " + documentUid.toString());
    const response = await this.doGet<Document>("/api/documents/" + documentUid.toString() + "/type/" + type.toString());
    //console.log("return getDocumentByDocumentUidAndType: " + JSON.stringify(response));
    return response;
  }



  async getDocumentsByCompanyUidDistance(companyId: string, movementId: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/company/" + companyId.toString() + "/movement/" + movementId.toString());
    return a;
  }


  async getDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Document>> {

    const documents = await this.getDocumentsByCompanyUid(companyUid);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    const returnpaginatedDocuments = paginatedDocuments.slice(start, end)
    console.log("getDocumentsByCompanyAndCriteria paginatedDocuments:" + JSON.stringify(returnpaginatedDocuments.length));
    return Promise.resolve(
      new Page<Document>(
        returnpaginatedDocuments.length,
        returnpaginatedDocuments
      )
    );
  }
  /*
    async getFullDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
      const documents = await this.getDocumentsByCompanyUid(companyUid);
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    }
    */

  async getFullDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    console.log("this.documentCache.length: " + this.documentCache.length);
    if (this.documentCache.length == 0) {
      documents = await this.getDocumentsByCompanyUid(companyUid);
      this.documentCache = documents;
    } else {
      documents = this.documentCache;
    }

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;

    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        paginatedDocuments.length,
        paginatedDocuments.slice(start, end)
      )
    );
  }

  async getFullIncomeDocumentsByCompanyClientAndCriteria(companyUid: string, clientUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();

    try {
      documents = await this.getIncomeDocumentsByCompanyAndClient(companyUid, clientUid);

      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;

      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    } catch (error) {
      console.log("Error getFullIncomeDocumentsByCompanyClientAndCriteria:" + error)
      return new PageFull<Document>(
        0,
        0,
        documents
      );
    }

  }







  /*
    async getFullIncomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
      const documents = await this.getIncomeDocumentsByCompanyUid(companyUid);
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    }
  */
  documentCache: Document[] = [];
  documentOutcomeCache: Document[] = [];
  documentIncomeCache: Document[] = [];
  cacheSize = 1000;

  async getFullIncomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    let documentsTMP = await this.getDocumentsByCompanyUidAndCriteriaInfra(companyUid, criteria);
    console.log("documentsTMP: " + JSON.stringify(documentsTMP));
    console.log("getFullIncomeDocumentsByCompanyAndCriteria: " + JSON.stringify(this.documentIncomeCache));

    if (this.documentIncomeCache.length == 0 || this.documentIncomeCache.length === undefined) {
      console.log("no data getFullIncomeDocumentsByCompanyAndCriteria : ");
      documents = await this.getIncomeDocumentsByCompanyUid(companyUid);
      this.documentIncomeCache = documents;
    } else {
      documents = this.documentIncomeCache;
    }
    //let pageRetrieved = this.cacheSize / criteria.pageSize;

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    console.log("start:" + start);
    console.log("end:" + end);
    const paginatedDocuments = this.filterByCriteria(criteria, documents);

    const returnpaginatedDocuments = paginatedDocuments.slice(start, end)


    console.log("paginatedDocuments:" + JSON.stringify(paginatedDocuments.length));

    //    const documents = await this.getIncomeDocumentsByCompanyUid(companyUid);

    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        returnpaginatedDocuments.length,
        returnpaginatedDocuments
      )
    );
  }


  async getFullOutcomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    console.log("getFullOutcomeDocumentsByCompanyAndCriteria: " + this.documentOutcomeCache);
    if (this.documentOutcomeCache.length == 0 || this.documentOutcomeCache.length === undefined) {
      documents = await this.getOutcomeDocumentsByCompanyUid(companyUid);
      console.log("llame api:" + documents.length);
      this.documentOutcomeCache = documents;
    } else {
      console.log("sin llamar  api:" + documents.length);
      documents = this.documentOutcomeCache;
    }

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    console.log("paginatedDocuments:" + JSON.stringify(paginatedDocuments));

    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        paginatedDocuments.length,
        paginatedDocuments.slice(start, end)
      )
    );
  }





  async getDocumentsByCompanyDistanceAndCriteria(companyId: string, movementId: string, criteria: Criteria): Promise<Page<Document>> {
    const documents = await this.getDocumentsByCompanyUidDistance(companyId, movementId);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    console.log("paginatedDocuments.length: " + paginatedDocuments.length);
    return Promise.resolve(
      new Page<Document>(
        paginatedDocuments.length,
        paginatedDocuments.slice(start, end)
      )
    );

  }








  //TODO: este filtro pareciera que deberia estar en APIRepository
  filterByCriteria(
    criteria: Criteria,
    _documents: Document[]
  ): Document[] {
    //TODO: implementar filtros
    let documents = Array<Document>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "company_uid": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.company_uid.toString().toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_number": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_number?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_type": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_sender_legal_id": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_sender_legal_id?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_state": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_state?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_balance": {

            if (_documents.length > 0) {
              documents = _documents.filter((document) => {
                if (filter.operator === ">") {
                  console.log("filtrando por " + filter.operator);
                  return document.document_balance &&
                    (document.document_balance > parseInt(filter.value))
                }
                if (filter.operator === "<") {
                  return document.document_balance &&
                    (document.document_balance < parseInt(filter.value))
                }
                if (filter.operator === ">=") {
                  return document.document_balance &&
                    (document.document_balance >= parseInt(filter.value))
                }
                if (filter.operator === "<=") {
                  return document.document_balance &&
                    (document.document_balance <= parseInt(filter.value))
                }
                if (filter.operator === "=") {
                  console.log("filtrando por " + filter.operator + " value: " + filter.value);
                  console.log("document.document_balance  " + document.document_balance);
                  if ((document.document_balance === parseInt(filter.value))) {
                    console.log("document:  " + JSON.stringify(document));
                  }

                  return (document.document_balance === parseInt(filter.value))
                }
                return false;
              }

              );
              console.log("documents.length pre: " + documents.length);
            }

            break;
          }

        }
      }
    }
    console.log("documents.length: " + documents.length);
    return documents;
  }



}





