import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { MovementRepository } from "../domain/MovementRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Page } from "~/context/Shared/domain/page/Page";
import { Movement } from "../domain/Movement";
import { MovementAccount } from "~/context/Shared/domain/MovementAccount";

export function createApiMovementRepository(): MovementRepository {
  return new APIMovementRepository();
}

export class APIMovementRepository extends APIRepository implements MovementRepository {
  constructor() {
    super(vectiAppApiUrl());
  }
  getMovements(): Promise<Movement[]> {
    throw new Error("Method not implemented.");
  }

  updateMovement(_movement: Movement): Promise<void> {
    throw new Error("Method not implemented.");
  }

  getMovementsByAccountUid(accountUid: string): Promise<Movement[]> {
    return this.doGet<Movement[]>("/api/movements/account/" + accountUid.toString());
  }

  getMovementsByCompanyUid(companyUid: string): Promise<Movement[]> {
    return this.doGet<Movement[]>("/api/movements/company/" + companyUid.toString());
  }

  getMovementsWithReconciliationsByCompanyUid(companyUid: string): Promise<Movement[]> {
    return this.doGet<Movement[]>("/api/movements/reconciliations/company/" + companyUid.toString());
  }


  getMovementsWithCompanyByCompanyUid(companyUid: string): Promise<MovementAccount[]> {
    return this.doGet<MovementAccount[]>("/api/compose/movements/company/" + companyUid.toString());
  }

  async getMovementsByCompanyUidDistance(companyId: string, documentId: string, type: string): Promise<Movement[]> {
    const a = await this.doGet<Movement[]>("/api/movements/company/" + companyId.toString() + "/document/" + documentId.toString() + "/type/" + type.toString());
    console.log("getMovementsByCompanyUidDistance: " + JSON.stringify(a));
    return a;
  }



  getMovementsByMovementUid(movementId: string): Promise<Movement | undefined> {
    return this.doGet<Movement>("/api/movements/" + movementId);
  }
  getMovementsByAccountUidPaginate(_movementUid: string, _page: number): Promise<Movement[]> {
    throw new Error("Method not implemented.");
  }
  getMovementsByCriteria(_criteria: Criteria): Promise<Page<Movement>> {

    throw new Error("Method not implemented.");
  }

  //TODO: revisar el cross que se produce por enviarle un ibjeto criteria. NO estoy usando companyId
  getMovementsByCompanyAccountAndCriteria(_companyUid: string, accountId: string, criteria: Criteria): Promise<Page<Movement>> {
    console.log("calling getMovementsByAccountAndCriteria API Movement");
    return this.getMovementsByAccountAndCriteria(accountId, criteria);

  }

  async getMovementsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Movement>> {
    const movements = await this.getMovementsByCompanyUid(companyUid);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedMovements = this.filterByCriteria(criteria, movements);
    return Promise.resolve(
      new Page<Movement>(
        paginatedMovements.length,
        paginatedMovements.slice(start, end)
      )
    );
  }

  async getMovementsWithReconciliationsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Movement>> {
    const movements = await this.getMovementsWithReconciliationsByCompanyUid(companyUid);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    console.log("movements: " + movements.length);
    console.log("movements: " + JSON.stringify(movements));
    const paginatedMovements = this.filterByCriteria(criteria, movements);
    return Promise.resolve(
      new Page<Movement>(
        paginatedMovements.length,
        paginatedMovements.slice(start, end)
      )
    );
  }


  async getMovementsByCompanyDistanceAndCriteria(companyUid: string, documentUid: string, type: string, criteria: Criteria): Promise<Page<Movement>> {
    const movements = await this.getMovementsByCompanyUidDistance(companyUid, documentUid, type);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedMovements = this.filterByCriteria(criteria, movements);
    return Promise.resolve(
      new Page<Movement>(
        paginatedMovements.length,
        paginatedMovements.slice(start, end)
      )
    );

  }



  async getMovementsWithCompanyByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<MovementAccount>> {
    const movementsAccount = await this.getMovementsWithCompanyByCompanyUid(companyUid);
    //console.log("getMovementsWithCompanyByCompanyAndCriteria movements: " + JSON.stringify(movementsAccount));
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedMovements = this.filterMovementAccountByCriteria(criteria, movementsAccount);
    return Promise.resolve(
      new Page<MovementAccount>(
        paginatedMovements.length,
        paginatedMovements.slice(start, end)
      )
    );

  }




  async getMovementsByAccountAndCriteria(accountUid: string, criteria: Criteria): Promise<Page<Movement>> {
    const movements = await this.getMovementsByAccountUid(accountUid);
    console.log("getMovementsByAccountAndCriteria movements: " + JSON.stringify(movements));
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedMovements = this.filterByCriteria(criteria, movements);
    return Promise.resolve(
      new Page<Movement>(
        paginatedMovements.length,
        paginatedMovements.slice(start, end)
      )
    );

  }


  filterByCriteria(
    criteria: Criteria,
    _movements: Movement[]
  ): Movement[] {
    //TODO: implementar filtros
    let movements = Array<Movement>();
    if (criteria.filters.length > 0 && _movements.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "movement_account_number": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_account_number
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_description": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_description
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_type": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "account_uid": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.account_uid
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_balance": {
            if (filter.operator === ">") {
              console.log("filtrando por " + filter.operator);
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance > parseFloat(filter.value)
              );
            } else if (filter.operator === "<") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance < parseFloat(filter.value)
              );
            } else if (filter.operator === ">=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance >= parseFloat(filter.value)
              );
            } else if (filter.operator === "<=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance <= parseFloat(filter.value)
              );
            } else if (filter.operator === "=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance === parseFloat(filter.value)
              );
            } else if (filter.operator === "!=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance != parseFloat(filter.value)
              );
            }

            break;
          }

          case "company_uid": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.company_uid
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }

          case "movement_amount": {
            if (filter.operator === ">") {
              console.log("filtrando por " + filter.operator);
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount > parseFloat(filter.value)
              );
            } else if (filter.operator === "<") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount < parseFloat(filter.value)
              );
            } else if (filter.operator === ">=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount >= parseFloat(filter.value)
              );
            } else if (filter.operator === "<=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount <= parseFloat(filter.value)
              );
            } else if (filter.operator === "=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount === parseFloat(filter.value)
              );
            } else if (filter.operator === "!=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount != parseFloat(filter.value)
              );
            }

            break;
          }



        }
      }
    }
    return movements;
  }

  filterMovementAccountByCriteria(
    criteria: Criteria,
    _movements: MovementAccount[]
  ): MovementAccount[] {
    //TODO: implementar filtros
    let movements = Array<MovementAccount>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "movement_account_number": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_account_number
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_description": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_description
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_type": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "account_uid": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.account_uid
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "account_alias": {
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.account_alias?.toLowerCase().includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "movement_balance": {
            if (filter.operator === ">") {
              console.log("filtrando por " + filter.operator);
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance > parseFloat(filter.value)
              );
            } else if (filter.operator === "<") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance < parseFloat(filter.value)
              );
            } else if (filter.operator === ">=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance >= parseFloat(filter.value)
              );
            } else if (filter.operator === "<=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance <= parseFloat(filter.value)
              );
            } else if (filter.operator === "=") {
              movements = _movements.filter((movement) =>
                movement.movement_balance !== undefined && movement.movement_balance === parseFloat(filter.value)
              );
            }

            break;
          }

          case "company_uid": {
            //    console.log("filter company_uid:");
            const alfa = filter.value.toLowerCase();
            console.log("alfa:" + alfa);
            if (_movements.length > 0) {
              movements = _movements.filter((movement) =>
                movement.movement?.company_uid?.toLowerCase().includes(alfa)
              );
              //    console.log("----> movements:" + JSON.stringify(movements.filter((movement) => movement.movement?.company_uid?.toLowerCase().includes(alfa))));
            }
            break;
          }

          case "movement_amount": {
            if (filter.operator === ">") {
              console.log("filtrando por " + filter.operator);
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount > parseFloat(filter.value)
              );
            } else if (filter.operator === "<") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount < parseFloat(filter.value)
              );
            } else if (filter.operator === ">=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount >= parseFloat(filter.value)
              );
            } else if (filter.operator === "<=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount <= parseFloat(filter.value)
              );
            } else if (filter.operator === "=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount === parseFloat(filter.value)
              );
            } else if (filter.operator === "!=") {
              movements = _movements.filter((movement) =>
                movement.movement_total_amount !== undefined && movement.movement_total_amount != parseFloat(filter.value)
              );
            }

            break;
          }


        }
      }
    }
    return movements;
  }



}
