import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router/auto";
import App from "./App.vue";

import "@mdi/font/css/materialdesignicons.min.css";

import "tailwindcss/tailwind.css";
import "../src/assets/css/modal-windows.css";
import "../src/assets/css/documents.css";
import { authGuard, createAuth0 } from "@auth0/auth0-vue";
//import { Dependencies } from "./context/Shared/infrastructure/Dependencies";
//import { getUserData } from "./context/Users/application/getUserData";
import {
  vectiAppAuth0ClientId,
  vectiAppAuth0Domain,
  vectiAppAuth0RedirectUrl,
} from "./context/Shared/infrastructure/Config";

const router = createRouter({
  history: createWebHistory(),
});
import store from "./store";

const auth0 = createAuth0({
  domain: vectiAppAuth0Domain(), //TODO: Parametrizar
  clientId: vectiAppAuth0ClientId(),
  authorizationParams: {
    redirect_uri: vectiAppAuth0RedirectUrl(), //TODO: Parametrizar
    audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
    //      skipRedirectCallback: true, // Evita que el callback funcione en cualquier URL
  },
});

const app = createApp(App);

// const authGuard = createAuthGuard(app);
router.beforeEach((to) => {
  if (to.path === "/") return true;
  if (to.path === "/auth/auth0") return true;
  const guard = authGuard(to);
  return new Promise<boolean>((resolve) => {
    guard.then((result) => {

      if (result === true) {
        /*
                if (!useUser().value) {
                  getUserData(Dependencies.getInstance().getUserRepository()).then(
                    (user) => {
                      useUser().value = user;
                    }
                  );
                  console.log("No hay usuario");
                }
                */
      }
      resolve(result);
    });
  });
});

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

app.use(vuetify);
app.use(router);
//@ts-expect-error
app.use(store);
app.use(auth0);

app.mount("#app");
