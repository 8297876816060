<template>
  <v-app>
    <!-- Fondo con color navy claro -->
    <v-card fluid class="bg-navy-light fill-height d-flex align-center justify-center">
      <v-row class="text-center" width="400px">
        <v-col>
          <!-- Texto principal -->
          <h1 class="display-1 white--text font-weight-bold mb-7">
            Landing page de vecti
            <br />
            <span class="blue-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam optio perferendis
              distinctio quo laudantium facilis ab veritatis corrupti assumenda accusamus alias voluptate aliquam,
              aspernatur quam sed quia debitis! Corporis, minus.</span>
            <br />
            y ordenadas con <span class="primary--text">Vecti</span>
          </h1>

          <!-- Bajada -->
          <h2 class="subtitle-1 white--text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet nihil odit harum sapiente! Ducimus
            repudiandae inventore incidunt dolor excepturi, quos maxime aperiam maiores sequi debitis libero repellat
            in! Veritatis, qui!
          </h2>

          <!-- Botón de llamada a la acción -->
          <v-btn color="primary" class="mt-10" large>
            ¡Comienza ahora!
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script lang="ts">
export default {
  name: "LandingPage",
};
</script>

<style scoped>
/* Color navy claro para el fondo */
.bg-navy-light {
  background-color: #5381be;
}

/* Ajustes para el texto en azul claro */
.blue-text {
  color: #4fc3f7;
}

/* Personalización para el color del botón */
.primary--text {
  color: #ff9800;
}
</style>