import { createStore } from "vuex";

const store = createStore({
  state: {
    inUseCompany: null,
    inUseCompanyHasAccount: null,
    userExtended: null,
    userCompanies: new Map<string, any>(),
    expandMenu: false,
    dialog: false,
    message: '',
    menuSelection: {
      movements: false,
      company: false,
      invoices: false,
      documents: false,
      outcomes: false,
      incomes: false,
    },
    welcomeParams: {
      firstName: '',
      lastName: '',
      gen: '',
      email: '',
      legalId: '',
      companyName: '',
      companyEmail: '',
    },
    notification: {
      show: false,
      message: '',
      duration: 1000,
    }
  },
  getters: {
    getInUseCompany(state: any) {
      return state.inUseCompany;
    },
    getUserExtended(state: any) {
      return state.userExtended;
    },
    getUserCompanies(state: any) {
      return state.userCompanies;
    },
    getExpandMenu(state: any) {
      return state.expandMenu;
    },
    getMenuSelection(state: any) {
      return state.menuSelection;
    },
    getInUseCompanyHasAccount(state: any) {
      return state.inUseCompanyHasAccount;
    },
    getWelcomeParams(state: any) {
      return state.welcomeParams;
    },
  },
  mutations: {
    setMenuSelection(state: any, value: any) {
      state.menuSelection = value;
    },
    setExpandMenu(state: any, value: any) {
      state.expandMenu = value;
    },
    setUserExtended(state: any, value: any) {
      state.userExtended = value;
    },
    setUserCompanies(state: any, value: any) {
      state.userCompanies = value;
    },
    setInUseCompany(state: any, value: any) {
      state.inUseCompany = value;
    },
    setInUseCompanyHasAccount(state: any, value: any) {
      state.inUseCompanyHasAccount = value;
    },
    setWelcomeParams(state: any, value: any) {
      state.welcomeParams = value;
    },
    addUserCompanyAccount(state: any, value: any) {
      console.log("value COMPANY:" + JSON.stringify(value));
      if (
        value.length > 0 &&
        !state.userCompanies.has(value[0].company_uid.toString())
      ) {
        state.userCompanies.set(value[0].company_uid.toString(), value);
        state.inUseCompanyHasAccount = true;
      }
    },
  },
  actions: {
    resetMenuSelection(context) {
      context.commit("setMenuSelection", {
        movements: false,
        company: false,
        invoices: false,
        documents: false,
        outcomes: false,
        incomes: false,
      });
    },
    closeDialog(context) {
      context.state.dialog = false;
      context.state.message = '';
    },
    openDialog(context, message) {
      context.state.dialog = true;
      context.state.message = message;
    },
    openNotification(context, options) {
      context.state.notification.show = true;
      context.state.notification.message = options.message;
      context.state.notification.duration = options.duration;
      setTimeout(() => {
        context.state.notification.show = false;
        context.state.notification.message = '';
      }, options.duration);
    },
    openError(context, options) {
      context.state.notification.show = true;
      context.state.notification.message = options.message;
      context.state.notification.duration = options.duration;
      setTimeout(() => {
        context.state.notification.show = false;
        context.state.notification.message = '';
      }, options.duration);
    },
  },
  modules: {},
});

export default store;
